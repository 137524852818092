<template>
  <div>
    <div class="totaltest totaltestTel">
      <audio ref="audio" autoplay controls="controls" :src="nowItem.audio"
             style="width: 0;height: 0;position: absolute;top: 0;">您的浏览器不支持 audio 标签。
      </audio>
      <img src="../../../assets/images/student/bluebackhome.png" alt="" @click="$router.push('/home')"
           style="margin: 50px 0 0 50px; cursor: pointer">
      <div class="content">
        <div v-if="!end">
          <div class="testWrap" :style="itemNum >= 100 ? 'margin-bottom: 20px' : ''">
            {{ testType == '1' ? '入学测试' : '结业测试' }}
          </div>
          <div class="msgWrap">
            <div class="left">
              <div class="txt1">{{ Math.round(nowItem.difficulty * 100) / 100 }}</div>
              <div class="txt2">词汇难度系数</div>
              <div class="txt3">（取值范围-3，3）</div>
            </div>
            <div class="right">
              <div class="time">
                <div class="text">剩余<span>{{ time }}</span>秒</div>
              </div>
              <div class="txt">预估词汇量：<span>{{ information.vocabulary }}</span></div>
              <div class="txt">测试正确率：<span>{{ Number(information.rightRate).toFixed(1) + '%' }}</span></div>
            </div>
          </div>
          <div class="word">
            <div class="wordSmall">
              <div class="txt">{{ nowItem.word }}</div>
              <div class="voice" @click="play" v-if="nowItem.audio"></div>
            </div>
          </div>
          <div class="options">
            <div class="option" v-for="(data, index) in nowItem.items" :key="data">
              <div class="answer" @click="selected(data, index)"
                   :class="{ yes: nowItem.rightAnswer === index && yesShow, no: noShow === index }">
                {{ data }}
              </div>
            </div>
          </div>
          <div class="btns" style="width: 200px;margin-top: 0px" v-if="itemNum >= 100">
            <div class="btn blue" @click="stop">结束测试</div>
          </div>
        </div>
        <div v-if="end">
          <div class="testWrap">测试结果</div>
          <div class="end">
            <div class="data">您的词汇量：<span>{{ information.vocabulary }}</span></div>
            <div class="data">您的正确率：<span>{{ Number(information.rightRate).toFixed(1) + '%' }}</span></div>
          </div>
          <div class="btns" style="width: 200px;">
            <div class="btn blue" @click="back">返回</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {nextItem, submitTotalTest, submitTest} from '@/api/student'
// import { getStuInfo, getClassInfo } from '@/util/auth' // 验权
import {imgUrl} from '@/utils/index'

export default {
  name: 'totaltest',
  components: {},
  data() {
    return {
      testType: '',
      end: false,
      time: 600,
      itemNum: 0,
      nowItem: {difficulty: 0, items: [], audio: ''},
      yesShow: false,
      noShow: '',
      timer: null,
      information: {vocabulary: 0, rightRate: 0},
      ban: true,
      imgUrl: imgUrl,
      yhid: this.$store.state.userInfo.yhid,
    }
  },
  created() {
    // 现在默认可以前测
    sessionStorage.setItem('testStart', true)
    if (sessionStorage.getItem('testStart') === 'true') {
      this.testType = this.$route.query.testType;
      const yhid = this.yhid
      nextItem({linkClassStuId: this.yhid, type: this.testType}).then(res => {
        this.nowItem = res.data
        this.itemNum++
      }).catch(err => {
        this.$message.warning('考试题获取失败，请刷新后重试')
      })
      this.timeStart()
    } else {
      // this.$router.replace({ name: 'assignmenthome' })
    }
  },
  mounted() {
  },
  methods: {
    stop() {
      this.timeStop()
      if (this.$route.name === 'totaltest') {
        const data = {
          testTime: 600 - this.time,
          testNum: this.itemNum,
          type: Number(this.testType),
          linkClassStuId: this.yhid,
          xsmc: JSON.parse(sessionStorage.getItem('userInfo')).xm,
        }
        submitTest(data).then(res => {
          console.log(res, 'hello');
        }).catch(() => {
          this.$message.warning('交卷失败，请刷新后重试')
        })
        this.end = true
      }
    },
    selected(data, index) {
      if (!this.ban) {
        return ''
      }
      this.ban = false
      this.timeStop()
      if (index !== this.nowItem.rightAnswer) {
        this.noShow = index
      }
      this.yesShow = true
      submitTotalTest({
        jxid: this.$route.query.jxid,
        type: Number(this.testType),
        linkClassStuId: this.yhid,
        answer: index === this.nowItem.rightAnswer ? 1 : 0,
        wordSn: this.nowItem.serialNumber
      }).then(res => {
        this.information = res.data.stat
        if (res.data.r * 100 >= 95) {
          this.stop()
          return ''
        }
        if (this.itemNum >= 150) {
          this.stop()
          return ''
        }
        this.itemNum++
        nextItem({linkClassStuId: this.yhid, type: this.testType}).then(ele => {
          if (index === this.nowItem.rightAnswer) {
            setTimeout(() => {
              this.yesShow = false
              this.nowItem = ele.data
              this.timeStart()
              this.ban = true
            }, 1000)
          } else {
            setTimeout(() => {
              this.yesShow = false
              this.noShow = ''
              this.nowItem = ele.data
              this.timeStart()
              this.ban = true
            }, 1500)
          }
        }).catch(err => {
          this.$message.warning('考试题获取失败，请刷新后重试')
        })
      }).catch(err => {
        this.$message.warning('答案提交失败，请刷新后重试')
      })
    },
    back() {
      sessionStorage.removeItem('testStart')
      this.$router.replace({name: 'home'})
    },
    play() {
      this.$refs.audio.play()
    },
    timeStart() {
      this.timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearInterval(this.timer)
          this.timer = null
          this.stop()
        }
      }, 1000)
    },
    timeStop() {
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1366px) {
  .totaltest.totaltestTel {
    .content {
      .testWrap {
        margin-bottom: 10px;
      }

      .options {
        padding-bottom: 20px;
      }
    }
  }
}

.totaltest {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../../assets/images/test/unitsBg.png") no-repeat;
  background-size: 100% 100%;

  .content {
    width: 870px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);

    .end {
      width: 550px;
      margin: 150px auto;
      overflow: hidden;
      margin-bottom: 20px;
      padding: 5px 25px;
      // border: 5px solid #baff79;
      border: 5px solid #4cb2f1;
      box-sizing: border-box;
      background: #fff;
      border-radius: 16px;

      .data {
        float: left;
        width: 50%;
        text-align: center;
        color: #333;
        font-size: 24px;
        line-height: 50px;

        span {
          color: #3a99ff;
        }
      }
    }

    .btns {
      overflow: hidden;
      width: 400px;
      margin: 120px auto;

      .btn {
        float: left;
        width: 180px;
        height: 50px;
        box-sizing: border-box;
        margin: 0 10px;
        text-align: center;
        font-size: 24px;
        line-height: 50px;
        color: #fff;
        border-radius: 15px;
        cursor: pointer;
      }

      .btn.blue {
        background: #4cd2f1;
        color: #fff;
      }

      .btn.yellow {
        background: #bbb;
        color: #fff;
      }

      .btn.green {
        background: #4cd2f1;
        color: #fff;
      }
    }

    .testWrap {
      width: 169px;
      height: 108px;
      background: url("../../../assets/images/test/testWrap.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      font-size: 23px;
      text-align: center;
      line-height: 136px;
      margin-bottom: 50px;
      font-weight: bold;
    }

    .msgWrap {
      width: 400px;
      margin: 0 auto;
      overflow: hidden;
      margin-bottom: 10px;

      .left {
        float: left;
        width: 140px;
        height: 123px;
        padding: 12px 0;
        // border: 5px solid #baff79;
        border: 5px solid #4cb2f1;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        text-align: center;

        .txt1 {
          font-size: 30px;
          color: #f53d3e;
          padding-bottom: 5px;
        }

        .txt2 {
          color: #333333;
          font-size: 16px;
        }

        .txt3 {
          color: #666666;
          font-size: 12px;
        }
      }

      .right {
        margin-left: 10px;
        float: left;
        width: 250px;
        height: 123px;
        padding: 5px 25px;
        // border: 5px solid #baff79;
        border: 5px solid #4cb2f1;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;

        .time {
          overflow: hidden;

          .text {
            float: left;
            font-size: 20px;
            margin-left: 10px;

            span {
              color: #ffb637;
              padding: 0 10px;
              font-size: 26px;
            }
          }
        }

        .txt {
          font-size: 16px;
          color: #333;
          margin-left: 8px;

          span {
            color: #3a99ff;
            font-size: 24px;
          }
        }
      }
    }

    .word {
      color: #333;
      font-size: 40px;
      position: relative;
      height: 50px;
      width: 100%;
      margin-bottom: 0px;

      .wordSmall {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        overflow: hidden;

        .txt {
          float: left;
        }

        .voice {
          float: left;
          background: url("../../../assets/images/test/audio.png") no-repeat;
          background-size: 100% 100%;
          width: 52px;
          height: 52px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .options {
      color: #333;
      padding: 5px 0 30px;

      .option {
        position: relative;
        height: 70px;

        .answer {
          width: 80%;
          text-align: center;
          font-size: 28px;
          display: inline-block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          -webkit-transform: translateX(-50%) translateY(-50%);
          cursor: pointer;
          line-height: 30px;
          border-radius: 10px;
          padding: 10px 20px;
          border: 2px solid #dadada;
          color: #003333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .answer.yes {
          background: #4cd2f1;
          border: 2px solid #fff;
          color: #fff;
        }

        .answer.yes:after {
          content: "";
          display: block;
          position: absolute;
          top: 10px;
          right: -35px;
          width: 30px;
          height: 30px;
          background: url("../../../assets/images/test/yes.png") no-repeat;
          background-size: 100% 100%;
        }

        .answer.yes:hover {
          background: #4cd2f1;
        }

        .answer.no {
          background: #ff1c21;
          color: #fff;
        }

        .answer.no:after {
          content: "";
          display: block;
          position: absolute;
          top: 10px;
          right: -35px;
          width: 30px;
          height: 30px;
          background: url("../../../assets/images/test/no.png") no-repeat;
          background-size: 100% 100%;
        }

        .answer.no:hover {
          background: #ff1c21;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.totaltest {
  .scrollbar .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
}
</style>